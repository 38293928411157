import React from 'react';
import PropTypes from 'prop-types';

import {PageWrapper} from '../components/templates';
import {Quote, SingleBreadcrumb, Divider} from '../components/common';
import {LayoutContainer, Column} from '../components/layouts';
import {Contribute, ListTitleRating, Opportunity} from '../components/organisms';
import {RelatedInsights} from '../components/templates';
import {textCenterDesktop} from '../styles/typography.module.css';
import createSlug from '../helpers/createSlug';

/**
 * @description Page template for 'single insight' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} Insight page React element.
 */
const Insight = ({pageContext}) => {
  const {quotes, taxonomies, ratings, opportunities} = pageContext;
  const {species, stage, lifestageName} = getTax();

  // TODO: This is just temp, hard-coded solution
  // We need to revise taxonomies
  let parentPage = (lifestageName === 'Puppy' || lifestageName === 'Kitten') ?
    lifestageName.toLowerCase() :
    species.toLowerCase();
  let stageToLink = ['Toddler', 'Adolescent', 'Baby', 'Young adult'].includes(stage) ? 'Adaptation' : stage;
  const backlink = `/${parentPage}/stage/${createSlug(stageToLink)}`;


  /**
   * @description Get taxonomies values.
   * @return {object} Object with taxonomies values.
   */
  function getTax() {
    let obj = {};
    if (taxonomies) {
      taxonomies.forEach(tax => {
        if (tax.__typename === 'ContentfulSpecies') {
          obj.species = tax['speciesName'];
        }
        else if (tax.__typename === 'ContentfulStage') {
          obj.stage = tax['title'];
        }
        else if (tax.__typename === 'ContentfulLifestage') {
          obj.lifestageName = tax['lifestageName'];
        }
      });
    }
    return obj;
  }

  /**
   * @description Generates string with meta tags from taxonomies.
   * @return {string} String created from taxonomies values.
   */
  const renderMeta = () => taxonomies.map(el => el.title || el.speciesName || el.lifestageName).join(', ');

  /**
   * @description Renders quotes components on the page.
   * @return {array} Array with quote components.
   */
  const renderQuotes = () => {
    return quotes.map(singleQuote => {
      if (singleQuote.quote) {
        return (
          <Quote
            key={singleQuote.id}
            quote={singleQuote.quote.quote}
            caption={singleQuote.caption} />
        );
      }
    });
  };

  const overallRating = pageContext.ratings ? pageContext.ratings[0].ratingValue : 0;

  return (
    <PageWrapper pageContext={{pageContext, title: pageContext.title.title}}>
      <LayoutContainer columns="one">
        <Column>
          {/* Breadcrumb */}
          <LayoutContainer columns="one" additionalClass="rc-max-width--xl">
            <Column>
              <div className="rc-margin-top--xs--desktop rc-margin-top--xl--mobile
                rc-margin-bottom--md rc-margin-left--lg--desktop">
                <SingleBreadcrumb label={stageToLink} link={backlink}/>
              </div>
            </Column>
          </LayoutContainer>
          <p className={`rc-meta rc-margin-bottom--md ${textCenterDesktop}`}>{renderMeta()}</p>
          {/* Title */}
          <h1 className={`rc-alpha rc-margin-bottom--xl--mobile rc-max-width--md rc-margin-bottom--lg
            ${textCenterDesktop}`}>{pageContext.title.title}</h1>
          {/* Ratings */}
          <ListTitleRating overallRating={overallRating} noRatings={!ratings}/>
          {/* Quotes */}
          <LayoutContainer columns="one" additionalClass="rc-max-width--lg rc-padding-x--lg--desktop">
            <Column>
              {quotes && quotes.length > 0 && renderQuotes()}
            </Column>
          </LayoutContainer>

          {/* Opportunities */}
          {opportunities && opportunities.length > 0 && (
            <Opportunity opportunities={opportunities}/>
          )}

          <Divider additionalClass="rc-margin-bottom--md"/>

          <Contribute pageTitle={pageContext.title.title}/>

          <Divider additionalClass="rc-margin-bottom--md"/>
        </Column>
      </LayoutContainer>
      <LayoutContainer additionalClass="rc-max-width--xl rc-margin-bottom--sm" columns="one">
        <Column>

          <RelatedInsights filterFunction={(item) => species.toLowerCase() === item.node.fields.speciesName}/>
        </Column>
      </LayoutContainer>
    </PageWrapper>
  );
};

Insight.propTypes = {
  pageContext: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired
};

export default Insight;
